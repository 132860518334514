<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('feUser.save') }}
              </button>
              <app-button-delete v-if="feUserBillingAddress.id" @deleteRecord="deleteRecord"></app-button-delete>
              <app-button-close @click="$router.go(-1)"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="col-lg-12" :class="{'has-danger': $v.feUserBillingAddress.feUserExpanded.$error}">
                      <div class="row">
                        <div class="col-lg-10">
                          <app-input v-if="feUserBillingAddress.id"
                                     v-model="feUserBillingAddress.feUserExpanded.email"
                                     id="feUserBillingAddress_feUserExpanded"
                                     :label="$t('feUser.user_email')"
                                     readonly
                          >
                          </app-input>
                          <label v-if="!feUserBillingAddress.id">{{ $t('feUser.user_email') }}</label>
                          <app-multi-select
                            v-if="!feUserBillingAddress.id"
                            v-model="feUserBillingAddress.feUserExpanded"
                            :options="feUsers"
                            :close-on-select="true"
                            :hide-selected="true"
                            :preserve-search="true"
                            placeholder="Select one"
                            label="email"
                            track-by="id"
                            :preselect-first="true"
                            :loading="isLoading"
                            :internal-search="false"
                            :options-limit="300"
                            :limit="10"
                            :max-height="600"
                            :show-no-results="false"
                            @search-change="findFeUser"
                            selectLabel=""
                            id="feUserBillingAddress_feUserExpanded"
                          >
                          </app-multi-select>
                        </div>
                        <div class="col-lg-2">
                          <router-link
                            v-if="feUserBillingAddress.feUserExpanded.email"
                            tag="a"
                            class="btn btn-default btn-sm w-100 mt-4 float-right"
                            :to="{ name: 'feUser_edit', params: { id: feUserBillingAddress.feUserExpanded.id }}"
                          >
                            {{ $t('feUser.detail') }}
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <app-select
                        v-model="feUserBillingAddress.type"
                        :options="valueObjectAddressType"
                        :label="$t('feUser.addressType')"
                        id="feUserBillingAddress_type"
                        noEmptyValue
                      >
                      </app-select>
                    </div>
                    <div class="col-lg-12">
                      <input type="hidden" v-model="feUserBillingAddress.id">
                      <app-input
                        v-model="feUserBillingAddress.name"
                        @blur="$v.feUserBillingAddress.name.$touch()"
                        :error="$v.feUserBillingAddress.name.$error"
                        id="feUserBillingAddress_name"
                        :label="$t('feUser.name')"
                      >
                      </app-input>
                    </div>
                    <div class="col-lg-12">
                      <app-input
                        v-model="feUserBillingAddress.surname"
                        id="feUserBillingAddress_surname"
                        :label="$t('feUser.surname')"
                      >
                      </app-input>
                    </div>
                    <div class="col-lg-12">
                      <app-input
                        v-model="feUserBillingAddress.street"
                        id="feUserBillingAddress_street"
                        :label="$t('feUser.street')"
                      >
                      </app-input>
                    </div>
                    <div class="col-lg-12">
                      <app-input
                        v-model="feUserBillingAddress.city"
                        id="feUserBillingAddress_city"
                        :label="$t('feUser.city')"
                      >
                      </app-input>
                    </div>
                    <div class="col-lg-12">
                      <app-input
                        v-model="feUserBillingAddress.zip"
                        id="feUserBillingAddress_zip"
                        :label="$t('feUser.zip')"
                      >
                      </app-input>
                    </div>
                    <div class="col-lg-12">
                      <label>{{ $t('feUser.aboCountry') }}</label>
                      <app-multi-select
                        v-model="feUserBillingAddress.aboCountryExpanded"
                        :options="aboCountries"
                        :close-on-select="true"
                        :hide-selected="true"
                        :preserve-search="true"
                        placeholder="Select one"
                        label="name"
                        track-by="id"
                        :preselect-first="true"
                        :loading="isLoading"
                        :internal-search="false"
                        :options-limit="300"
                        :limit="10"
                        :max-height="600"
                        :show-no-results="false"
                        @search-change="findCountry"
                        selectLabel=""
                        id="feUserBillingAddress_aboCountryExpanded"
                      >
                      </app-multi-select>
                    </div>
                    <div class="col-lg-12 mt-4">
                      <app-input
                        v-model="feUserBillingAddress.phone"
                        id="feUserBillingAddress_phone"
                        :label="$t('feUser.phone')"
                      >
                      </app-input>
                    </div>
                    <div class="col-lg-12">
                      <app-input
                        v-model="feUserBillingAddress.company"
                        id="feUserBillingAddress_company"
                        :label="$t('feUser.company')"
                      >
                      </app-input>
                    </div>
                    <div class="col-lg-12">
                      <app-input
                        v-model="feUserBillingAddress.ico"
                        id="feUserBillingAddress_ico"
                        :label="$t('feUser.ico')"
                      >
                      </app-input>
                    </div>
                    <div class="col-lg-12">
                      <app-input
                        v-model="feUserBillingAddress.dic"
                        id="feUserBillingAddress_dic"
                        :label="$t('feUser.dic')"
                      >
                      </app-input>
                    </div>
                    <div class="col-lg-12">
                      <app-input
                        v-model="feUserBillingAddress.icDph"
                        id="feUserBillingAddress_icDph"
                        :label="$t('feUser.icDph')"
                      >
                      </app-input>
                    </div>
                  </div>
                  <div class="col-lg-3">
                  </div>
                  <div class="col-lg-3">
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../ModuleView'
import FeUserBillingAddressModel from '../../../model/feUser/FeUserBillingAddress'
import Input from '../../form/inputs/Input'
import Select from '../../form/select/Select'
import ButtonDelete from '../../shared/ButtonDelete'
import ButtonClose from '../../shared/ButtonClose'
import { required, maxLength } from 'vuelidate/lib/validators'
import NotifyService from '../../../services/NotifyService'
import MultiSelect from 'vue-multiselect'
import ValueObjectService from '../../../services/ValueObjectService'
import FeUserFilterModel from '../../../model/feUser/FeUserFilter'

export default {
  name: 'FeUserNew',
  data () {
    return {
      isLoading: false,
      feUserBillingAddress: this._.cloneDeep(FeUserBillingAddressModel)
    }
  },
  computed: {
    aboCountries () {
      return this.$store.getters['aboCountry/list']
    },
    feUsers () {
      return this.$store.getters['feUser/list']
    },
    valueObjectAddressType () {
      return ValueObjectService.getAsArray(
        this.$store.getters['feUserBillingAddress/valueObjectAddressType'],
        true
      )
    }
  },
  validations: {
    feUserBillingAddress: {
      feUserExpanded: {
        required
      },
      name: {
        required,
        maxLength: maxLength(180)
      },
      surname: {
        maxLength: maxLength(180)
      },
      street: {
        maxLength: maxLength(255)
      },
      city: {
        maxLength: maxLength(255)
      },
      zip: {
        maxLength: maxLength(255)
      },
      phone: {
        maxLength: maxLength(255)
      },
      type: {
        required
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appSelect: Select,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appMultiSelect: MultiSelect
  },
  methods: {
    prepareFeUserBillingAddressRequest (feUserBillingAddress) {
      feUserBillingAddress.feUser = this.$store.getters['feUser/detail'].id
      if (feUserBillingAddress.aboCountryExpanded && feUserBillingAddress.aboCountryExpanded.id) {
        feUserBillingAddress.aboCountry = feUserBillingAddress.aboCountryExpanded.id
      }
      if (feUserBillingAddress.feUserExpanded && feUserBillingAddress.feUserExpanded.id) {
        feUserBillingAddress.feUser = feUserBillingAddress.feUserExpanded.id
      }
      delete feUserBillingAddress.feUserExpanded
      delete feUserBillingAddress.aboCountryExpanded
      return feUserBillingAddress
    },
    async save () {
      this.$v.$touch()

      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('feUser.error.required_fields'))
        return
      }

      // Check if user exists
      await this.$store.dispatch('feUser/fetchOne', this.feUserBillingAddress.feUser)
        .then(() => {
          if (this.$store.getters['feUser/error'] !== null) {
            NotifyService.setErrorMessage(this.$store.getters['feUser/error'])
          }
        })
        .catch(error => console.log(error))
      if (this.$store.getters['feUser/error'] !== null) {
        return
      }

      // Create user address
      this.$store.dispatch(
        'feUserBillingAddress/create',
        this.prepareFeUserBillingAddressRequest(this.feUserBillingAddress)
      )
        .then(() => {
          this.feUserBillingAddress = this.$store.getters['feUserBillingAddress/detail']
          if (this.$store.getters['feUserBillingAddress/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('feUser.message.updated_record'))
            this.$router.push('/feUserBillingAddress/' + this.feUserBillingAddress.id + '/edit')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['feUserBillingAddress/error'])
          }
        })
        .catch(error => console.log(error))
    },
    deleteRecord () {
      this.$store.dispatch('feUserBillingAddress/deleteRecord', this.feUserBillingAddress)
        .then(() => {
          if (this.$store.getters['feUserBillingAddress/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('feUser.message.deleted_record'))
            this.$router.push('/feUserBillingAddress')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['feUserBillingAddress/error'])
          }
        })
        .catch(error => console.log(error))
    },
    findCountry (query) {
      this.$store.dispatch('aboCountry/fetch', query)
    },
    findFeUser (query) {
      const filter = this._.cloneDeep(FeUserFilterModel)
      filter.email = query
      this.$store.commit('feUser/setFilter', filter)
      this.$store.dispatch('feUser/fetch')
    }
  },
  created () {
    if (this.$route.params.feUserId) {
      const filter = this._.cloneDeep(FeUserFilterModel)
      filter.id = this.$route.params.feUserId
      this.$store.commit('feUser/setFilter', filter)
      this.$store.dispatch('feUser/fetch')
    }
  },
  destroyed () {
    this.$store.commit('feUser/setFilter', this._.cloneDeep(FeUserFilterModel))
    this.$store.dispatch('feUser/fetch')
  },
  beforeMount () {
    this.$store.dispatch('feUserBillingAddress/fetchValueObjectAddressType')
      .then(() => {
        // Set default value
        this.feUserBillingAddress.type = ValueObjectService.getAsArray(
          this.$store.getters['feUserBillingAddress/valueObjectAddressType'],
          true
        ).shift().id
      })
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
div.has-danger .multiselect__tags {
  border-color: red;
}
</style>
